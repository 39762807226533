import styled, { css, keyframes } from 'styled-components';

interface SkeletonBoneTypoProps {
  boneWidth?: number;
}

const basicBackgroundColor = '#f2f2f2';

const skeletonGradient = `
  linear-gradient(to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(252, 252, 252, .7),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
`;

const gradientKeyframes = keyframes`
  0% {background-position:100% 0%}
  100% {background-position:0% 0%}
`;

const cssAnimation = css`
  background: ${skeletonGradient};
  background-size: 400%;
  animation-name: ${gradientKeyframes};
  animation-duration: 1.5s;
  animation-direction: normal;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const SkeletonBone = styled.span`
  background-color: ${basicBackgroundColor};
  ::before {
    width: 100%;
    height: 100%;
    display: inline-block;
    content: '\\00A0';
    ${cssAnimation}
  }
`;

export const SkeletonBonePicture = styled(SkeletonBone)`
  display: block;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%; // 16:9 aspect-ratio
  width: 100%;

  ::before {
    position: absolute;
    top: 0;
  }
`;

export const SkeletonBoneTypo = styled(SkeletonBone)`
  margin-right: 10px;
  ::before {
    width: ${(props: SkeletonBoneTypoProps) => (props.boneWidth ? props.boneWidth : 100)}%;
  }
`;
