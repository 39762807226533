export function splitArrayInTwo<T>(array: T[]): [T[], T[]] {
  const half = Math.ceil(array.length / 2);
  return [array.slice(0, half), array.slice(half)];
}

export function splitArrayInThree<T>(array: T[]): [T[], T[], T[]] {
  const third = Math.ceil(array.length / 3);
  const twoThird = third * 2;
  return [array.slice(0, third), array.slice(third, twoThird), array.slice(twoThird)];
}
