import { Button } from '@audi/audi-ui-react';
import React from 'react';
import { useGeneralFilterFunctions } from '../../context/FilterContext';

interface SubmitButtonProps {
  handleClick: () => void;
  close: () => void;
}
const SubmitButton = ({ handleClick, close }: SubmitButtonProps) => {
  const { buttonText } = useGeneralFilterFunctions();

  return (
    <Button
      variant="secondary"
      data-testid="submit-button"
      stretch
      onClick={() => {
        handleClick();
        close();
      }}
    >
      {buttonText}
    </Button>
  );
};

export default SubmitButton;
