import { Text } from '@audi/audi-ui-react';
import React from 'react';
import { useShowBasePrice } from '../../context/Context';
import { useGeneralFilterFunctions } from '../../context/FilterContext';
import DesktopBodyFilter from './BodyFilter';
import { DesktopFilterContainer } from './DesktopFilterStyles';
import FilterState from './FilterState';
import MobileFilter from './MobileFilter';
import DesktopModelFilter from './ModelFilter';
import DesktopPriceFilter from './PriceFilter';
// import DesktopFuelFilter from './FuelFilter';

export const Filter = () => {
  const { resultCountText } = useGeneralFilterFunctions();
  const showBasePrice = useShowBasePrice();

  return (
    <>
      <MobileFilter />
      <DesktopFilterContainer>
        <DesktopModelFilter />
        <DesktopBodyFilter />
        {showBasePrice && <DesktopPriceFilter />}
        {/* <DesktopFuelFilter /> */}
      </DesktopFilterContainer>
      <FilterState />
      <Text data-testid="result-count" variant="copy2">
        {resultCountText}
      </Text>
    </>
  );
};
