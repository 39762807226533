import styled from 'styled-components';
import { Button, Popover } from '@audi/audi-ui-react';

export const DesktopFilterContainer = styled.div`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: flex;
  }
`;

export const FilterContainer = styled.div`
  position: relative;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: var(${(props) => props.theme.responsive.spacing.l});
  gap: var(${(props) => props.theme.responsive.spacing.l});
  &.three-cols {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
export const StyledBodyTypeGrid = styled(StyledGrid)`
  @media (max-width: ${(props) => props.theme.breakpoints.l}px) {
    &.three-cols {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const StyledFilterContainer = styled.div`
  min-height: var(${(props) => props.theme.responsive.spacing.xxxl});
  padding-bottom: var(${(props) => props.theme.responsive.spacing.m});
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: flex;
  }
`;

export const StyledButton = styled('button').withConfig({
  shouldForwardProp: (prop) => !['onPress'].includes(prop),
})`
  background-color: transparent;
  padding-left: 0;
  padding-right: var(${(props) => props.theme.responsive.spacing.l});
  border: none;
  display: flex;
  cursor: pointer;
`;

export const StyledPopover = styled(Popover)`
  padding: var(${(props) => props.theme.responsive.spacing.m})
    var(${(props) => props.theme.responsive.spacing.xs});
  min-width: max-content;
`;

export const RemoveAllButton = styled(Button)`
  margin: 0;
`;
