import * as React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  color: ${(props) => props.theme.colors.ui.primary};
  pointer-events: none;
  flex-shrink: 0;
`;

export const FilterIcon: React.FC = () => (
  <StyledIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <svg width="24" height="24" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.64581 0.5H19.3541L12.6875 7.9C12.2708 8.3 12.0625 8.8 12.0625 9.3V14.5L8.93748 16.5V9.3C8.93748 8.8 8.72915 8.3 8.31248 7.9L1.64581 0.5Z"
        stroke="black"
      />
    </svg>
  </StyledIcon>
);
