import React, { createContext, PropsWithChildren, useContext } from 'react';
import { InitialState } from '../FeatureAppTypes';

interface State {
  readonly initialState: InitialState;
}

export const Context = createContext<State>({} as State);

interface InitialStateContextProviderProps {
  readonly initialState: InitialState;
}

export const InitialStateContextProvider = ({
  children,
  initialState,
}: PropsWithChildren<InitialStateContextProviderProps>) => {
  return (
    <Context.Provider
      value={{
        initialState,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useInitialState = () => useContext(Context).initialState;
