/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import { LayoutItem, Text } from '@audi/audi-ui-react';
import React, { useEffect, useState } from 'react';
import { AsyncStateHolder, InitialState } from './FeatureAppTypes';
import Disclaimer from './components/disclaimer/Disclaimer';
import EditModeWarning from './components/edit-mode-warning/EditModeWarning';
import ListAndFilter from './components/list-and-filter/ListAndFilter';
import Skeleton from './components/skeleton/Skeleton';
import { useLogger, useModalOpenState } from './context/Context';
import { FilterContextProvider } from './context/FilterContext';
import { InitialStateContextProvider } from './context/InitialStateContext';
import { useTrackingManager } from './context/useTrackingManager';
import { useI18n } from './i18n';
import { LayoutItemWithPageMargin } from './styles';

const FeatureApp: React.FC = () => {
  const [isModalOpen, { closeModal }] = useModalOpenState();

  useEffect(() => {
    let mounted = true;
    const handleResize = () => {
      if (mounted && window.innerWidth >= 768) {
        closeModal();
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      mounted = false;
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const heading = useI18n('heading');
  return (
    <LayoutItemWithPageMargin className={isModalOpen ? 'open' : ''}>
      <LayoutItem>
        <Text variant="order1" spaceStackEnd="xxl" data-testid="headline">
          {heading}
        </Text>
        <FilterContextProvider>
          <ListAndFilter />
        </FilterContextProvider>
      </LayoutItem>
    </LayoutItemWithPageMargin>
  );
};

interface Props {
  readonly asyncStateHolder: AsyncStateHolder;
}

const AsyncFeatureApp: React.FunctionComponent<Props> = ({ asyncStateHolder }: Props) => {
  // when asyncStateHolder is an object it represents the serialized
  // state coming from the server ready to be used as the
  // initial state
  const [state, setState] = useState<InitialState | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined,
  );

  const logger = useLogger();
  const trackingManager = useTrackingManager();

  useEffect(() => {
    let mounted = true;
    trackingManager.ready(__FEATURE_APP_VERSION__);
    // when asyncStateHolder is a function it means the state could not be properly serialized by the server and it is
    // not available on the client. In that case this effect will try to
    // fetch the state as soon as the component is mounted on the client.
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SSR did not serialize any state');
      asyncStateHolder().then((updatedState) => mounted && setState(updatedState));
    } else {
      logger?.info('SSR serialized state: ', asyncStateHolder);
    }

    return () => {
      mounted = false;
    };
  }, []);

  if (!state) {
    return <Skeleton />;
  }
  return (
    <InitialStateContextProvider initialState={state}>
      <EditModeWarning />
      <FeatureApp />
      <Disclaimer />
    </InitialStateContextProvider>
  );
};

export default AsyncFeatureApp;
