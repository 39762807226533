import type { Logger } from '@feature-hub/core';
import { MoxxCarlineGroup } from '../MoxxTypes';

export const URL_PARAM_BY_CARLINEGROUP = 'bycarlinegroup';
export const URL_PARAM_BY_TYPE = 'bytype';

/**
 * Extracts and parses URL parameters to generate an initial filter configuration.
 *
 * @param { string } urlParamName - The name of the URL parameter to extract.
 * @param { string[][] } filterEntries - An array of filter entries, representing key-value pairs.
 * @param { Logger } [logger] - An optional logger for error handling and logging.
 *
 * @returns { Map<string, string> | undefined } - A Map containing the initial filter configuration
 *
 */
export const getInitialFilter = (
  urlParamName: string,
  filterEntries: string[][],
  logger?: Logger,
): Map<string, string> | undefined => {
  try {
    const search: string | undefined = globalThis?.location?.search;
    if (search?.length > 0) {
      const urlParams = new URLSearchParams(search);
      const urlId = urlParams.get(urlParamName);
      if (urlId) {
        const idArr = urlId.split(',').map((item) => item.trim());
        const initialFilter = new Map<string, string>();

        idArr.forEach((element) => {
          const filterEntry = filterEntries.find(([id]) => id === element);
          if (filterEntry) {
            initialFilter.set(filterEntry[0], filterEntry[1]);
          }
        });
        return initialFilter;
      }
    }
  } catch (e) {
    logger?.error(e);
  }
  return undefined;
};

/**
 * Retrieves the initial carline group filter configuration based on URL parameters or pre-filtered group ID.
 *
 * @param { MoxxCarlineGroup[] } carlineGroups - An array of carline groups.
 * @param { string } preFilteredCarlineGroupId - The pre-filtered group ID, if available.
 * @param { Logger } [logger] - An optional logger for error handling and logging.
 * @returns { Map<string, string> | undefined } - A Map containing the initial carline group filter configuration
 *
 */
export const getInitialCarlineGroupFilter = (
  carlineGroups: MoxxCarlineGroup[],
  preFilteredCarlineGroupId: string,
  logger?: Logger,
) => {
  const carlineGroupsArray = carlineGroups.map<[string, string]>(({ id, name }) => [id, name]);
  const initialCarlineGroupFilter = getInitialFilter(
    URL_PARAM_BY_CARLINEGROUP,
    carlineGroupsArray,
    logger,
  );
  if (initialCarlineGroupFilter) {
    return initialCarlineGroupFilter;
  }
  if (preFilteredCarlineGroupId.length > 0) {
    const preFilteredCarlineGroup = carlineGroupsArray.find(
      ([id]) => id === preFilteredCarlineGroupId,
    );
    if (preFilteredCarlineGroup) {
      return new Map<string, string>([preFilteredCarlineGroup]);
    }
  }
  return undefined;
};
