import { Text } from '@audi/audi-ui-react';
import React from 'react';
import styled from 'styled-components';
import { isEditMode } from '../../context/Context';
import { useInitialState } from '../../context/InitialStateContext';
import { getCarlineIdsWithoutDiscoverLinks } from '../../utils/getCarlineIdsWithoutDiscoverLinks';

const ErrorMessage = styled.span`
  color: var(${({ theme }) => theme.colors.ui.error});
  display: block;
`;

const EditModeWarning = () => {
  const editMode = isEditMode();
  const { carlineGroups } = useInitialState();
  const carlineIdsWithoutDiscoverLinks = getCarlineIdsWithoutDiscoverLinks(carlineGroups);

  if (!editMode || carlineIdsWithoutDiscoverLinks.length === 0) {
    return null;
  }

  return (
    <Text as="h1" variant="order3">
      <ErrorMessage>
        There are carlines without discover link attached. The feature app will still render, but
        this is an error on the data side that needs to be fixed. Affected carline ids: [
        {carlineIdsWithoutDiscoverLinks.join(', ')}]
      </ErrorMessage>
    </Text>
  );
};

export default EditModeWarning;
