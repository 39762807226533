import React from 'react';
import { useCarlineGroupFilter } from '../../context/FilterContext';
import { useInitialState } from '../../context/InitialStateContext';
import { splitArrayInThree } from '../../utils/splitArray';
import DesktopFilter from './DesktopFilter';

const DesktopModelFilter: React.FC = () => {
  const { carlineGroups } = useInitialState();
  const carlineGroupFilter = useCarlineGroupFilter();

  const [carlineGroups1, carlineGroups2, carlineGroups3] = splitArrayInThree(
    carlineGroups.map<[string, string]>(({ id, name }) => [id, name]),
  );

  return (
    <DesktopFilter
      testId="model-filter"
      filter={carlineGroupFilter}
      filterEntries1={carlineGroups1}
      filterEntries2={carlineGroups2}
      filterEntries3={carlineGroups3}
      filterText="modelFilterText"
      filterTextWithValue="modelFilterTextWithValue"
      threeCols
    />
  );
};

export default DesktopModelFilter;
