import type { Logger } from '@feature-hub/core';
import type { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import type { I18NServiceV1 } from '@oneaudi/i18n-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import {
  FuelType,
  VehicleType,
  type CarlineGroup,
  type CarlineStructure,
} from '../../web/generated/onegraph';
import type { I18nMessageKey, I18nMessages, InitialState } from '../FeatureAppTypes';
import type { MoxxCarline } from '../MoxxTypes';
import { filterMockedAndEmptyCarlineGroups } from '../context/Context';
import { fetchI18nMessages, matchI18nCategories } from '../i18n';
import { getCarlineIdsWithoutDiscoverLinks } from './getCarlineIdsWithoutDiscoverLinks';
import { createAsyncCarlineFetch } from './getCarlines';
import { mapToMoxxCarlineGroup } from './mapToMoxxCarlineGroup';

let i18nMessages: I18nMessages;
let categoriesI18nMessages: Record<string, string>;

const addFuelType =
  (carlines: MoxxCarline[], fuelTypesMap: Map<string, string>) =>
  (vehicleType: VehicleType, fuelType: FuelType | 'HYBRID', i18nKey: I18nMessageKey) => {
    const atLeastOneFound = carlines.some(({ vehicleType: vt }) => vehicleType === vt);
    if (atLeastOneFound) {
      fuelTypesMap.set(fuelType, i18nMessages[i18nKey]);
    }
  };

const addIcevFuelType =
  (fuelTypes: FuelType[], fuelTypesMap: Map<string, string>) =>
  (matchingFuelType: FuelType, i18nKey: I18nMessageKey) => {
    const petrolExists = fuelTypes.some((fuelType) => fuelType === matchingFuelType);
    if (petrolExists) {
      fuelTypesMap.set(matchingFuelType, i18nMessages[i18nKey]);
    }
  };

const addFuelTypes = (carlines: MoxxCarline[], fuelTypesMap: Map<string, string>) => {
  const add = addFuelType(carlines, fuelTypesMap);
  add(VehicleType.Bev, FuelType.Electrical, 'electrical');
  add(VehicleType.Phev, 'HYBRID', 'hybrid');

  const icevFuelTypes = carlines
    .filter(({ vehicleType }) => VehicleType.Icev === vehicleType)
    .flatMap(({ fuelTypes }) => fuelTypes);
  const addIcev = addIcevFuelType(icevFuelTypes, fuelTypesMap);
  addIcev(FuelType.Cng, 'cng');
  addIcev(FuelType.Petrol, 'petrol');
  addIcev(FuelType.Diesel, 'diesel');
};

export async function createInitialState(
  oneGraphService: OneGraphServiceV1,
  localeService: GfaLocaleServiceV1,
  i18nService: I18NServiceV1,
  logger?: Logger,
): Promise<InitialState> {
  if (!i18nMessages) {
    const [i18n, categoriesI18n] = await fetchI18nMessages(localeService, i18nService);
    i18nMessages = i18n;
    categoriesI18nMessages = categoriesI18n;
  }

  const fetchedCarlines: CarlineStructure = await createAsyncCarlineFetch(
    oneGraphService,
    localeService,
  );

  const carlineGroups = filterMockedAndEmptyCarlineGroups(fetchedCarlines.carlineGroups).map(
    (carlineGroup: CarlineGroup) => mapToMoxxCarlineGroup(carlineGroup),
  );

  const bodyTypesMap = new Map<string, string>();
  const fuelTypesMap = new Map<string, string>();
  carlineGroups.forEach(({ carlines }) => {
    addFuelTypes(carlines, fuelTypesMap);
    carlines.forEach((carline) => {
      carline.categories.forEach((category) => {
        if (!bodyTypesMap.has(category)) {
          bodyTypesMap.set(category, matchI18nCategories(category, categoriesI18nMessages));
        }
      });
    });
  });

  const carlineIdsWithoutDiscoverLinks = getCarlineIdsWithoutDiscoverLinks(carlineGroups);
  if (carlineIdsWithoutDiscoverLinks.length > 0) {
    logger?.error(
      `There are carlines without discover link attached. The feature app will still render, but this is an error on the data side that needs to be fixed. Affected carline ids:`,
      carlineIdsWithoutDiscoverLinks,
    );
  }

  const bodyTypes = Array.from(bodyTypesMap.entries());
  const fuelTypes = Array.from(fuelTypesMap.entries());
  return {
    carlineGroups,
    bodyTypes,
    fuelTypes,
    i18nMessages,
  };
}

/**
 * Helper function to deserialize the state of the feature app. It converts serialized
 * ReactNodeArray Entries that aren't string to FootnoteReference components
 * @param state
 */

export function deserializeState(state: string): InitialState {
  const props = JSON.parse(state);

  return {
    ...props,
  };
}

export function getStockAmount(carline: MoxxCarline) {
  const availableCarsInStock = carline.availableStock;

  let carsInStock = 0;
  if (availableCarsInStock?.newCars && availableCarsInStock?.newCars?.amount) {
    carsInStock += availableCarsInStock.newCars.amount;
  }
  if (availableCarsInStock?.usedCars && availableCarsInStock.usedCars?.amount) {
    carsInStock += availableCarsInStock.usedCars.amount;
  }
  return carsInStock;
}
