import styled from 'styled-components';
import { COLOR_BASE_BRAND_WHITE } from '@audi/audi-ui-design-tokens';

export const MobileFilterContainer = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: none;
  }
`;

export const AccordionContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow-y: auto;
  left: 0;
  top: 0;
  background-color: ${COLOR_BASE_BRAND_WHITE};
  z-index: 1000;
`;

export const AccordionHeading = styled.div`
  padding: var(${(props) => props.theme.responsive.spacing.xl});
  display: grid;
  grid-template-columns: auto max-content;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(${(props) => props.theme.responsive.spacing.l});
  @media (max-width: ${(props) => props.theme.breakpoints.s}px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledBodyTypeGrid = styled(StyledGrid)`
  @media (max-width: ${(props) => props.theme.breakpoints.m}px) {
    grid-template-columns: 1fr;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
`;

export const ButtonContainer = styled.div`
  margin: auto var(${(props) => props.theme.responsive.spacing.l})
    var(${(props) => props.theme.responsive.spacing.xxl});

  padding-top: var(${(props) => props.theme.responsive.spacing.xxl});
`;

export const MobileContainer = styled.div`
  display: flex;
  min-height: var(${(props) => props.theme.responsive.spacing.xxxl});
  padding-bottom: var(${(props) => props.theme.responsive.spacing.xxxl});
`;
