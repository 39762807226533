import { LayoutItem, Text } from '@audi/audi-ui-react';
import React from 'react';
import styled from 'styled-components';
import { LayoutItemWithPageMargin } from '../../styles';
import { StyledCardHeader, StyledCard } from '../card/CardStyles';

import { StyledWrapper } from '../wrapper/Wrapper';
import { SkeletonBonePicture, SkeletonBoneTypo } from './styles';

const NUMBER_OF_CARDS = 8;

const DesktopText = styled.div`
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: block;
  }
`;
const MobileText = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: none;
  }
`;

const SkeletonHeader: React.FC = () => {
  return (
    <>
      <DesktopText>
        <Text variant="order2" data-test-id="wrapper" spaceStackEnd="xl" spaceStackStart="xxl">
          <SkeletonBoneTypo boneWidth={7} />
          <SkeletonBoneTypo boneWidth={7} />
          <SkeletonBoneTypo boneWidth={7} />
        </Text>
      </DesktopText>
      <MobileText>
        <Text variant="order2" data-test-id="wrapper" spaceStackEnd="xl" spaceStackStart="xxl">
          <SkeletonBoneTypo boneWidth={15} />
        </Text>
      </MobileText>
    </>
  );
};

const SkeletonCard: React.FC = () => {
  return (
    <StyledCard>
      <StyledCardHeader>
        <LayoutItem spaceStackEnd="xs">
          <Text variant="order3" data-test-id="card">
            <SkeletonBoneTypo boneWidth={50} />
          </Text>
        </LayoutItem>
      </StyledCardHeader>
      <SkeletonBonePicture />
      <Text variant="order4" spaceStackStart="m">
        <SkeletonBoneTypo boneWidth={70} />
      </Text>
      <Text variant="order4" spaceStackStart="m">
        <SkeletonBoneTypo boneWidth={40} />
      </Text>
    </StyledCard>
  );
};

const Skeleton: React.FC = () => {
  return (
    <LayoutItemWithPageMargin>
      <LayoutItem>
        <Text variant="order2">
          <SkeletonBoneTypo boneWidth={20} />
        </Text>
        <SkeletonHeader />
        <Text variant="order2" data-test-id="wrapper" spaceStackEnd="xl" spaceStackStart="xxl">
          <SkeletonBoneTypo boneWidth={7} />
        </Text>
        <Text variant="order2" data-test-id="wrapper" spaceStackEnd="xl" spaceStackStart="xxl">
          <SkeletonBoneTypo boneWidth={15} />
        </Text>
        <StyledWrapper>
          {(() => {
            const cards = [];
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < NUMBER_OF_CARDS; i++) {
              cards.push(<SkeletonCard key={i} />);
            }

            return cards;
          })()}
        </StyledWrapper>
      </LayoutItem>
    </LayoutItemWithPageMargin>
  );
};

export default Skeleton;
