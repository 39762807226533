import { Tag } from '@audi/audi-ui-react';
import React from 'react';
import { I18nMessages } from '../../FeatureAppTypes';
import { matchI18n } from '../../i18n';

export const createTags = (
  mode: string,
  filter: Map<string, string>,
  removeHandler: (id: string) => void,
) => {
  return Array.from(filter.entries()).map(([id, name]) => {
    return (
      <Tag
        data-testid="filter-tag"
        key={id}
        label={name}
        id={`${mode}_${id}`}
        removable
        onRemove={() => {
          removeHandler(id);
        }}
      />
    );
  });
};

export const createMinPriceTag = (
  formattedMinPrice: string,
  removeHandler: () => void,
  i18nMessages: I18nMessages,
) => {
  return (
    <Tag
      data-testid="filter-tag"
      label={`${matchI18n('from', i18nMessages)} ${formattedMinPrice}`}
      removable
      onRemove={removeHandler}
    />
  );
};

export const createMaxPriceTag = (
  formattedMaxPrice: string,
  removeHandler: () => void,
  i18nMessages: I18nMessages,
) => {
  return (
    <Tag
      data-testid="filter-tag"
      label={`${matchI18n('to', i18nMessages)} ${formattedMaxPrice}`}
      removable
      onRemove={removeHandler}
    />
  );
};
