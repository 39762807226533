import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { useInitialState } from '../context/InitialStateContext';
import { I18nMessageKey, I18nMessages } from '../FeatureAppTypes';

const i18nScope = 'fa.audi-feature-app-model-overview';
const filterTypeScope = 'mofi.filter.types';

export function useI18n(key: I18nMessageKey): string {
  const { i18nMessages } = useInitialState();
  return i18nMessages[key] ?? `__${i18nScope}.${key}__`;
}

export function matchI18n(
  i18nKey: I18nMessageKey,
  i18nMessages: I18nMessages,
  params?: { [key: string]: string },
): string {
  const textFromI18n = i18nMessages[i18nKey] ?? `__${i18nScope}.${i18nKey}__`;
  if (params && Object.keys(params).length > 0) {
    return Object.keys(params)
      .map((key: string): string => textFromI18n?.replace(`\${${key}}`, params[key]))
      .toString();
  }
  return textFromI18n;
}

export function matchI18nCategories(i18nKey: string, i18nMessages: Record<string, string>): string {
  return i18nMessages[i18nKey] ?? `__${filterTypeScope}.${i18nKey}__`;
}

export async function fetchI18nMessages(
  localeService: GfaLocaleServiceV1,
  i18nService: I18NServiceV1,
): Promise<[I18nMessages, Record<string, string>]> {
  return new Promise((resolve, reject) => {
    try {
      i18nService.setScopes([i18nScope, filterTypeScope]);
      i18nService.registerCallback((messages) => {
        const categoriesI18n = Object.entries(messages[localeService.language]).reduce(
          (acc: Record<string, string>, [key, value]) => {
            if (key.includes(filterTypeScope)) {
              // eslint-disable-next-line no-param-reassign
              key = key.replace(`${filterTypeScope}.`, '');

              acc[key] = value;
            }

            return acc;
          },
          {},
        );
        const res = Object.entries(messages[localeService.language]).reduce(
          (acc: Record<string, string>, [key, value]) => {
            // TODO: remove this as soon as i18nService is CMS agnostic (keys don't required FA prefix/scope)
            // messages are prefixed with the i18nScope (feature app name: fa.cct-teaser). This is required due to
            // how AEM handles translations. In order to avoid verbosity in the source code the scope is removed from
            // the keys to make them match the required I18nMessageKey.
            // This way translations can be used like `useI18n('headline')`
            // instead of `useI18n('fa.cct-teaser.headline')`
            if (key.includes(i18nScope)) {
              // eslint-disable-next-line no-param-reassign
              key = key.replace(`${i18nScope}.`, '');

              acc[key] = value;
            }

            return acc;
          },
          {},
        ) as I18nMessages;

        resolve([res, categoriesI18n]);
      });
    } catch (err) {
      reject(err);
    }
  });
}
