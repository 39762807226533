import { Button, Text } from '@audi/audi-ui-react';
import React from 'react';
import styled from 'styled-components';
import { EraseIcon } from '../filter/icons/EraseIcon';

import { useI18n } from '../../i18n';
import { I18nTexts } from '../../FeatureAppTypes';
import { useGeneralFilterFunctions } from '../../context/FilterContext';

export const NoResultsContainer = styled.div`
  text-align: center;
  margin-top: 40%;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-top: 20%;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    margin-top: 10%;
  }
`;

export const ResetFilterButton = styled.button`
  white-space: nowrap;
`;

export const NoResults = () => {
  const { clearFilters } = useGeneralFilterFunctions();

  const i18nTexts: I18nTexts = {
    noResults: useI18n('noResults'),
    noModels: useI18n('noModels'),
    deleteAllFilters: useI18n('deleteAllFilters'),
  };

  return (
    <NoResultsContainer>
      <Text variant="order2">{i18nTexts.noResults}</Text>
      <Text variant="copy1" spaceStackStart="m">
        {i18nTexts.noModels}
      </Text>
      <ResetFilterButton>
        <Button
          data-testid="reset-filter-button"
          variant="secondary"
          spaceStackStart="l"
          icon={<EraseIcon />}
          onClick={() => {
            clearFilters();
          }}
        >
          <Text variant="copy1">{i18nTexts.deleteAllFilters}</Text>
        </Button>
      </ResetFilterButton>
    </NoResultsContainer>
  );
};
