import { PopoverBody, PopoverTrigger, Text } from '@audi/audi-ui-react';
import React, { useRef, useState } from 'react';

import { ArrowIcon } from '../ArrowIcon';
import {
  FilterContainer,
  StyledGrid,
  StyledButton,
  StyledPopover,
  StyledBodyTypeGrid,
} from './DesktopFilterStyles';
import SubmitButton from './SubmitButton';
import { matchI18n, useI18n } from '../../i18n';
import { I18nMessageKey } from '../../FeatureAppTypes';
import { useInitialState } from '../../context/InitialStateContext';
import { Filter, useGeneralFilterFunctions } from '../../context/FilterContext';
import { mapToFilterCheckboxes } from './FilterCheckbox';

export interface DesktopFilterProps {
  readonly filter: Filter;
  readonly filterEntries1: [string, string][];
  readonly filterEntries2?: [string, string][];
  readonly filterEntries3?: [string, string][];
  readonly filterText: string;
  readonly filterTextWithValue: string;
  readonly threeCols?: boolean;
  readonly testId: string;
}

const DesktopFilter: React.FC<DesktopFilterProps> = ({
  testId,
  filter,
  filterEntries1,
  filterEntries2,
  filterEntries3,
  filterText,
  filterTextWithValue,
  threeCols = false,
}) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const amountOfFiltersSet = filter.filter.size;
  const targetRef = useRef(null);
  const { resetFilters } = useGeneralFilterFunctions();
  const { i18nMessages } = useInitialState();
  const staticFilterText = useI18n(filterText as I18nMessageKey);

  const GridComponent = filterText === 'bodyFilterText' ? StyledBodyTypeGrid : StyledGrid;

  return (
    <FilterContainer>
      <PopoverTrigger
        onClose={() => {
          setFilterOpen(false);
        }}
        targetRef={targetRef}
        keepContentHeight
      >
        {(triggerProps, close) => {
          return (
            <>
              <StyledButton
                {...triggerProps}
                data-testid={testId}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  triggerProps.onClick();
                  resetFilters();
                  setFilterOpen(!filterOpen);
                }}
              >
                <Text variant="copy1" weight={amountOfFiltersSet > 0 ? 'bold' : 'normal'}>
                  {amountOfFiltersSet > 0
                    ? matchI18n(filterTextWithValue as I18nMessageKey, i18nMessages, {
                        value: amountOfFiltersSet.toString(),
                      })
                    : staticFilterText}
                </Text>
                <ArrowIcon rotated={filterOpen} />
              </StyledButton>
              <StyledPopover>
                <PopoverBody>
                  <GridComponent className={threeCols ? 'three-cols' : undefined}>
                    <div>{mapToFilterCheckboxes(Array.from(filterEntries1), filter, testId)}</div>
                    {filterEntries2 && (
                      <div>{mapToFilterCheckboxes(Array.from(filterEntries2), filter, testId)}</div>
                    )}
                    {filterEntries3 && (
                      <div>{mapToFilterCheckboxes(Array.from(filterEntries3), filter, testId)}</div>
                    )}
                  </GridComponent>
                  <SubmitButton handleClick={() => filter.save()} close={close} />
                </PopoverBody>
              </StyledPopover>
            </>
          );
        }}
      </PopoverTrigger>
    </FilterContainer>
  );
};

export default DesktopFilter;
