import { FormGroup, PopoverBody, PopoverTrigger, Select, Text } from '@audi/audi-ui-react';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useInitialState } from '../../context/InitialStateContext';
import { ArrowIcon } from '../ArrowIcon';
import { FilterContainer, StyledButton, StyledPopover } from './DesktopFilterStyles';
import SubmitButton from './SubmitButton';
import { matchI18n, useI18n } from '../../i18n';
import { I18nMessageKey } from '../../FeatureAppTypes';
import { useGeneralFilterFunctions, usePriceFilter } from '../../context/FilterContext';

export const StyledPriceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(${(props) => props.theme.responsive.spacing.l});
  @media (max-width: ${(props) => props.theme.breakpoints.s}px) {
    grid-template-columns: 1fr;
  }
`;

const DesktopPriceFilter: React.FC = () => {
  const priceFilter = usePriceFilter();
  const { i18nMessages } = useInitialState();
  const [filterOpen, setFilterOpen] = useState(false);
  const targetRef = useRef(null);
  const { resetFilters } = useGeneralFilterFunctions();
  const staticFilterText = useI18n('priceFilterText' as I18nMessageKey);
  const amountOfPriceFiltersSet = (priceFilter.minPrice ? 1 : 0) + (priceFilter.maxPrice ? 1 : 0);
  const selecMaxPricetLabel = `${matchI18n('to', i18nMessages)} ${matchI18n(
    'currency',
    i18nMessages,
  )}`;
  const selectMinPriceLabel = `${matchI18n('from', i18nMessages)} ${matchI18n(
    'currency',
    i18nMessages,
  )}`;
  const selectAnyPriceLabel = matchI18n('anyPrice', i18nMessages);

  return (
    <FilterContainer>
      <PopoverTrigger
        onClose={() => {
          setFilterOpen(false);
        }}
        targetRef={targetRef}
      >
        {(triggerProps, close) => {
          return (
            <>
              <StyledButton
                data-testid="price-filter"
                {...triggerProps}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  triggerProps.onClick();
                  resetFilters();
                  setFilterOpen(!filterOpen);
                }}
              >
                <Text
                  ref={targetRef}
                  variant="copy1"
                  weight={amountOfPriceFiltersSet > 0 ? 'bold' : 'normal'}
                >
                  {amountOfPriceFiltersSet > 0
                    ? matchI18n('priceFilterTextWithValue' as I18nMessageKey, i18nMessages, {
                        value: amountOfPriceFiltersSet.toString(),
                      })
                    : staticFilterText}
                </Text>
                <ArrowIcon rotated={filterOpen} />
              </StyledButton>
              <StyledPopover>
                <PopoverBody>
                  <FormGroup spaceStackEnd="l">
                    {/* <StyledPriceGrid>
                      <RadioButton inputId="radio-button-basic">
                        {i18nMessages.totalPrice}
                      </RadioButton>
                      <RadioButton inputId="radio-button-basic">
                        {i18nMessages.leasingrate}
                      </RadioButton>
                    </StyledPriceGrid> */}
                    <StyledPriceGrid>
                      <Select
                        data-testid="min-price-filter"
                        inputId="select__basic"
                        required
                        label={selectMinPriceLabel}
                        onChange={(e) => {
                          const price = parseInt(e.currentTarget.value, 10);
                          priceFilter.setMinPrice(price);
                        }}
                        value={priceFilter.temporaryMinPrice.toString()}
                      >
                        <option value="0">{selectAnyPriceLabel}</option>
                        {priceFilter.createMinPriceOptions()}
                      </Select>

                      <Select
                        data-testid="max-price-filter"
                        inputId="select__basic"
                        required
                        label={selecMaxPricetLabel}
                        onChange={(e) => {
                          priceFilter.setMaxPrice(parseInt(e.currentTarget.value, 10));
                        }}
                        value={priceFilter.temporaryMaxPrice.toString()}
                      >
                        <option value="0">{selectAnyPriceLabel}</option>
                        {priceFilter.createMaxPriceOptions()}
                      </Select>
                    </StyledPriceGrid>
                  </FormGroup>
                  <SubmitButton handleClick={() => priceFilter.save()} close={close} />
                </PopoverBody>
              </StyledPopover>
            </>
          );
        }}
      </PopoverTrigger>
    </FilterContainer>
  );
};

export default DesktopPriceFilter;
