import { Text } from '@audi/audi-ui-react';
import React from 'react';
import styled from 'styled-components';
import { COLOR_BASE_GREY_5 } from '@audi/audi-ui-design-tokens';
import { useDisclaimer } from '../../context/Context';

export const StyledDisclaimer = styled.div`
  padding: var(${(props) => props.theme.responsive.spacing.l});
  background: ${COLOR_BASE_GREY_5};
`;

const Disclaimer = () => {
  const disclaimer = useDisclaimer();

  if (disclaimer && disclaimer.trim().length === 0) {
    return null;
  }

  return (
    <StyledDisclaimer>
      <Text variant="copy2" data-testid="disclaimer">
        {disclaimer}
      </Text>
    </StyledDisclaimer>
  );
};

export default Disclaimer;
