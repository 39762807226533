import { Logger } from '@feature-hub/logger';

const appendWidthToUrl = (url: string, width: number, logger?: Logger) => {
  try {
    const newURLObject = new URL(url);
    newURLObject.searchParams.set('width', `${width}`);
    newURLObject.searchParams.set('mimetype', 'image/jpeg');
    return newURLObject.toString();
  } catch (err) {
    logger?.error('Error while adding width parameter to image URL', err);
    return url;
  }
};

export { appendWidthToUrl };
