import { RenderMode } from '@oneaudi/render-mode-service';
import type { FC, PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import type { CarlineGroup } from '../../web/generated/onegraph';
import type { Content } from '../EditorContentTypes';
import type { I18nMessages } from '../FeatureAppTypes';
import { mapHeadlessContent } from '../utils/mapHeadlessContent';
import type { ContextProviderProps, ContextState } from './ContextTypes';

export function filterMockedAndEmptyCarlineGroups(carlineGroups: CarlineGroup[]): CarlineGroup[] {
  const noEmptyCarlineGroups = carlineGroups.filter(({ carlines }) => carlines.length > 0);

  return noEmptyCarlineGroups;
}
let i18nMessages: I18nMessages;

export const Context = createContext<ContextState>({} as ContextState);

export const hasShowBasePrice = (content: Content) =>
  content.priceDisplayOption?.showBasePrice === true;

export const getPreFilteredCarlineGroupId = (content: Content): string =>
  content.preFilteredCarlineGroup?.id?.trim() || '';

const getMinPriceFilterOptions = (content: Content): number[] =>
  content.priceFilterOptions?.minPriceOptions || [];

const getMaxPriceFilterOptions = (content: Content): number[] =>
  content.priceFilterOptions?.maxPriceOptions || [];

const hasShowCaeData = (content: Content) => content.caeDisplayOption.showCae === true;

const hasShowConfigureCTA = (content: Content) =>
  content.ctaDisplayOptions.showConfigureCTA === true;

const hasShowNewAndUsedCarsCTA = (content: Content) =>
  content.ctaDisplayOptions.showNewAndUsedCarsCTA === true;

export const ContextProvider: FC<ContextProviderProps> = ({
  featureAppId,
  localeService,
  logger,
  trackingService,
  children,
  i18nService,
  contentService,
  renderModeService,
  numberFormatterService,
}: PropsWithChildren<ContextProviderProps>) => {
  const initialContent = contentService.getContent();
  const content = mapHeadlessContent(initialContent);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showBasePrice, setShowBasePrice] = useState<boolean>(hasShowBasePrice(content));
  const [preFilteredCarlineGroupId, setPreFilteredCarlineGroupId] = useState<string>(
    getPreFilteredCarlineGroupId(content),
  );
  const [isEditMode] = useState<boolean>(renderModeService?.getRenderMode() === RenderMode.EDIT);
  const [minPriceFilterOptions, setMinPriceFilterOptions] = useState<number[]>(
    getMinPriceFilterOptions(content),
  );
  const [maxPriceFilterOptions, setMaxPriceFilterOptions] = useState<number[]>(
    getMaxPriceFilterOptions(content),
  );
  const [showCaeData, setShowCaeData] = useState<boolean>(hasShowCaeData(content));
  const [showConfigureCTA, setShowConfigureCTA] = useState<boolean>(hasShowConfigureCTA(content));
  const [showNewAndUsedCarsCTA, setShowNewAndUsedCarsCTA] = useState<boolean>(
    hasShowNewAndUsedCarsCTA(content),
  );
  const [priceFootnotes, setPriceFootnotes] = useState<string[] | undefined>(
    content.priceFootnotes,
  );
  const [disclaimer, setDisclaimer] = useState<string>(content.disclaimer.text);

  useEffect(() => {
    let mounted = true;

    const contentChangeListener = () => {
      if (mounted) {
        const newInitialContent = contentService.getContent();
        const newContent = mapHeadlessContent(newInitialContent);
        setShowBasePrice(hasShowBasePrice(newContent));
        setPreFilteredCarlineGroupId(getPreFilteredCarlineGroupId(newContent));
        setMinPriceFilterOptions(getMinPriceFilterOptions(newContent));
        setMaxPriceFilterOptions(getMaxPriceFilterOptions(newContent));
        setShowCaeData(hasShowCaeData(newContent));
        setShowConfigureCTA(hasShowConfigureCTA(newContent));
        setShowNewAndUsedCarsCTA(hasShowNewAndUsedCarsCTA(newContent));
        setPriceFootnotes(newContent.priceFootnotes);
        setDisclaimer(newContent.disclaimer.text);
      }
    };
    contentService.onContentChange(contentChangeListener);

    return () => {
      mounted = false;
      contentService.removeOnContentChange(contentChangeListener);
    };
  }, []);

  return (
    <Context.Provider
      value={{
        featureAppId,
        modalOpenState: [
          isModalOpen,
          { openModal: () => setIsModalOpen(true), closeModal: () => setIsModalOpen(false) },
        ],
        i18nService,
        i18nMessages,
        logger,
        trackingService,
        showBasePrice,
        preFilteredCarlineGroupId,
        isEditMode,
        minPriceFilterOptions,
        maxPriceFilterOptions,
        showCaeData,
        showConfigureCTA,
        showNewAndUsedCarsCTA,
        localeService,
        priceFootnotes,
        disclaimer,
        numberFormatterService,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const usePriceFootnotes = () => useContext(Context).priceFootnotes;

export const useModalOpenState = () => useContext(Context).modalOpenState;

export const useFeatureAppId = () => useContext(Context).featureAppId;

export const useDisclaimer = () => useContext(Context).disclaimer;

export const useLogger = () => useContext(Context).logger;

export const useLocaleService = () => useContext(Context).localeService;

export const useI18nService = () => useContext(Context).i18nService;

export const useTrackingService = () => useContext(Context).trackingService;

export const usePreFilteredCarlineGroupId = () => useContext(Context).preFilteredCarlineGroupId;

export const useShowBasePrice = () => useContext(Context).showBasePrice;

export const isEditMode = () => useContext(Context).isEditMode;

export const useMinPriceFilterOptions = () => useContext(Context).minPriceFilterOptions;

export const useMaxPriceFilterOptions = () => useContext(Context).maxPriceFilterOptions;

export const useShowCaeData = () => useContext(Context).showCaeData;

export const useShowConfigureCTA = () => useContext(Context).showConfigureCTA;

export const useShowNewAndUsedCarsCTA = () => useContext(Context).showNewAndUsedCarsCTA;

export const useNumberFormatter = (nr: number) =>
  useContext(Context).numberFormatterService.formatNumber(nr);

export const useCurrencyFormatter = (nr: number) =>
  useContext(Context).numberFormatterService.formatCurrency(nr);
