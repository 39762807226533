import type { PropsWithChildren } from 'react';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { I18nTexts } from '../FeatureAppTypes';
import type { MoxxCarlineGroup } from '../MoxxTypes';
import { matchI18n, useI18n } from '../i18n';
import { filter, getNumberOfCarlinesAfterFilter } from '../utils/filterCarlineData';
import {
  URL_PARAM_BY_TYPE,
  getInitialCarlineGroupFilter,
  getInitialFilter,
} from '../utils/filterContextUtils';
import {
  useCurrencyFormatter,
  useLogger,
  useMaxPriceFilterOptions,
  useMinPriceFilterOptions,
  usePreFilteredCarlineGroupId,
} from './Context';
import { useInitialState } from './InitialStateContext';
import { useTrackingManager } from './useTrackingManager';

interface CommonFilterFunctions {
  readonly clearAndSave: () => void;
  readonly reset: () => void;
  readonly save: () => void;
}

export interface Filter extends CommonFilterFunctions {
  readonly filter: Map<string, string>;
  readonly temporaryFilter: Map<string, string>;
  readonly set: (id: string, name: string) => void;
  readonly delete: (id: string) => void;
  readonly deleteAndSave: (id: string) => void;
  readonly has: (id: string) => boolean;
}

export interface PriceFilter extends CommonFilterFunctions {
  readonly minPrice: number;
  readonly temporaryMinPrice: number;
  readonly setMinPrice: (price: number) => void;
  readonly maxPrice: number;
  readonly temporaryMaxPrice: number;
  readonly setMaxPrice: (price: number) => void;
  readonly resetMinPrice: () => void;
  readonly resetMaxPrice: () => void;
  readonly createMinPriceOptions: () => JSX.Element[];
  readonly createMaxPriceOptions: () => JSX.Element[];
}

const createFilter = (initialFilter = new Map<string, string>()): Filter => {
  const [state, setState] = useState(initialFilter);
  const [temporaryState, setTemporaryState] = useState(new Map<string, string>());

  /**
   * Adds a filter to the temporary filter state. Don't forget to save().
   * @param id
   * @param name
   */
  const set = useCallback((id: string, name: string) => {
    setTemporaryState((value) => {
      const newTemporaryState = new Map(value);
      newTemporaryState.set(id, name);
      return newTemporaryState;
    });
  }, []);

  /**
   * Deletes a filter from the temporary filter state. Don't forget to save().
   * @param id
   */
  const deleteFn = useCallback((id: string) => {
    setTemporaryState((value) => {
      const newTemporaryState = new Map(value);
      newTemporaryState.delete(id);
      return newTemporaryState;
    });
  }, []);

  /**
   * Deletes a filter from the temporary filter state saves.
   * @param id
   */
  const deleteAndSave = useCallback((id: string) => {
    setTemporaryState((value) => {
      const newTemporaryState = new Map(value);
      newTemporaryState.delete(id);
      return newTemporaryState;
    });
    setState((value) => {
      const newState = new Map(value);
      newState.delete(id);
      return newState;
    });
  }, []);

  const has = useCallback((id: string) => temporaryState.has(id), [temporaryState]);
  /**
   * Empties the temporary filter state. Don't forget to save().
   */
  const clearAndSave = () => {
    setTemporaryState(() => new Map());
    setState(() => new Map());
  };

  /**
   * Resets the temporary filter state to the last saved state.
   */
  const reset = useCallback(() => {
    setTemporaryState(() => new Map(state));
  }, [state]);

  /**
   * Saves the current temporary filter state.
   */
  const save = useCallback(() => {
    setState(() => new Map(temporaryState));
  }, [temporaryState]);

  return {
    filter: state,
    temporaryFilter: temporaryState,
    set,
    delete: deleteFn,
    deleteAndSave,
    has,
    clearAndSave,
    reset,
    save,
  };
};

const mapPriceToOption = (price: number, text: string, disabled = false) => {
  return (
    <option key={price} value={price} disabled={disabled}>
      {text}
    </option>
  );
};

const createPriceFilter = (): PriceFilter => {
  const genericFilter = createFilter();
  const minPrices = useMinPriceFilterOptions();
  const maxPrices = useMaxPriceFilterOptions();
  const [minPrice, setMinPrice] = useState(0);
  const [temporaryMinPrice, setTemporaryMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [temporaryMaxPrice, setTemporaryMaxPrice] = useState(0);
  const createMinPriceOptions = useCallback(() => {
    return minPrices.map((price) =>
      mapPriceToOption(
        price,
        useCurrencyFormatter(price).toString(),
        temporaryMaxPrice > 0 && price >= temporaryMaxPrice,
      ),
    );
  }, [minPrices, temporaryMaxPrice]);

  const createMaxPriceOptions = useCallback(() => {
    return maxPrices.map((price) =>
      mapPriceToOption(price, useCurrencyFormatter(price).toString(), price <= temporaryMinPrice),
    );
  }, [maxPrices, temporaryMinPrice]);

  const reset = useCallback(() => {
    setTemporaryMinPrice(minPrice);
    setTemporaryMaxPrice(maxPrice);
  }, [minPrice, maxPrice]);

  const save = useCallback(() => {
    setMinPrice(temporaryMinPrice);
    setMaxPrice(temporaryMaxPrice);
  }, [temporaryMinPrice, temporaryMaxPrice]);

  const resetMinPrice = useCallback(() => {
    setTemporaryMinPrice(0);
    setMinPrice(0);
  }, []);

  const resetMaxPrice = useCallback(() => {
    setTemporaryMaxPrice(0);
    setMaxPrice(0);
  }, []);

  const clearAndSave = () => {
    resetMinPrice();
    resetMaxPrice();
  };

  return {
    ...genericFilter,
    minPrice,
    temporaryMinPrice,
    maxPrice,
    temporaryMaxPrice,
    setMinPrice: setTemporaryMinPrice,
    setMaxPrice: setTemporaryMaxPrice,
    createMinPriceOptions,
    createMaxPriceOptions,
    reset,
    save,
    clearAndSave,
    resetMinPrice,
    resetMaxPrice,
  };
};

interface GeneralFilterFunctions {
  readonly resetFilters: () => void;
  readonly clearFilters: () => void;
  readonly buttonText: string;
  readonly resultCountText: string;
}

interface State {
  readonly filteredCarlineGroups: MoxxCarlineGroup[];
  readonly carlineGroupsFilter: Filter;
  readonly bodyTypeFilter: Filter;
  readonly priceFilter: PriceFilter;
  readonly generalFilterFunctions: GeneralFilterFunctions;
  readonly fuelFilter: Filter;
}

export const FilterContext = createContext<State>({} as State);

export const FilterContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const logger = useLogger();
  const preFilteredCarlineGroupId = usePreFilteredCarlineGroupId();
  const { carlineGroups, i18nMessages, bodyTypes } = useInitialState();

  const carlineGroupsFilter = createFilter(
    getInitialCarlineGroupFilter(carlineGroups, preFilteredCarlineGroupId, logger),
  );

  const bodyTypeFilter = createFilter(getInitialFilter(URL_PARAM_BY_TYPE, bodyTypes, logger));
  const fuelFilter = createFilter();
  const priceFilter = createPriceFilter();
  const [filteredCarlineGroups, setFilteredCarlineGroups] = useState(
    filter(carlineGroups, {
      selectedCarlineGroups: Array.from(carlineGroupsFilter.filter.keys()),
      selectedBodyTypes: Array.from(bodyTypeFilter.filter.keys()),
      selectedFuelTypes: Array.from(fuelFilter.filter.keys()),
      selectedMinPrice: priceFilter.minPrice,
      selectedMaxPrice: priceFilter.maxPrice,
    }),
  );
  const staticI18nText: I18nTexts = {
    noResults: matchI18n('noResults', i18nMessages),
    showAllCars: matchI18n('showAllCars', i18nMessages),
  };
  const { trackFilterClick } = useTrackingManager();
  const [buttonText, setButtonText] = useState(staticI18nText.showAllCars);
  const buttonTextForTracking = useI18n('showCars');

  const numberOfCarlines = useMemo(() => {
    return getNumberOfCarlinesAfterFilter(carlineGroups, {
      selectedCarlineGroups: Array.from(carlineGroupsFilter.filter.keys()),
      selectedBodyTypes: Array.from(bodyTypeFilter.filter.keys()),
      selectedMinPrice: priceFilter.minPrice,
      selectedMaxPrice: priceFilter.maxPrice,
      selectedFuelTypes: Array.from(fuelFilter.filter.keys()),
    });
  }, [
    carlineGroupsFilter.filter,
    bodyTypeFilter.filter,
    priceFilter.minPrice,
    priceFilter.maxPrice,
    fuelFilter.filter,
  ]);
  const createResultCountText = useCallback(() => {
    return numberOfCarlines === 1
      ? matchI18n('numberOfResult', i18nMessages, {
          value: numberOfCarlines.toString(),
        })
      : matchI18n('numberOfResults', i18nMessages, {
          value: numberOfCarlines.toString(),
        });
  }, [numberOfCarlines]);

  const temporaryNumberOfCarlines = useMemo(() => {
    return getNumberOfCarlinesAfterFilter(carlineGroups, {
      selectedCarlineGroups: Array.from(carlineGroupsFilter.temporaryFilter.keys()),
      selectedBodyTypes: Array.from(bodyTypeFilter.temporaryFilter.keys()),
      selectedMinPrice: priceFilter.temporaryMinPrice,
      selectedMaxPrice: priceFilter.temporaryMaxPrice,
      selectedFuelTypes: Array.from(fuelFilter.temporaryFilter.keys()),
    });
  }, [
    [
      carlineGroups,
      carlineGroupsFilter.temporaryFilter,
      bodyTypeFilter.temporaryFilter,
      priceFilter.temporaryMinPrice,
      priceFilter.temporaryMaxPrice,
      fuelFilter.temporaryFilter,
    ],
  ]);

  useEffect(() => {
    const carlineGroup = getInitialCarlineGroupFilter(
      carlineGroups,
      preFilteredCarlineGroupId,
      logger,
    );
    if (carlineGroup) {
      carlineGroupsFilter.temporaryFilter.clear();
      carlineGroup.forEach((name, id) => {
        carlineGroupsFilter.temporaryFilter.set(id, name);
      });
      carlineGroupsFilter.save();
    }
  }, [preFilteredCarlineGroupId]);

  useEffect(() => {
    const filterIDs = [];
    const relatedFilters = [];
    const minPriceFilter = priceFilter.minPrice ?? 0;
    const maxPriceFilter = priceFilter.maxPrice ?? 0;
    if (minPriceFilter || maxPriceFilter) {
      filterIDs.push('price');
      relatedFilters.push({
        id: 'price',
        values: [minPriceFilter, maxPriceFilter],
      });
    }
    if (carlineGroupsFilter.filter.size > 0) {
      filterIDs.push('carlineGroup');
      relatedFilters.push({
        id: 'carline-group',
        values: Array.from(carlineGroupsFilter.filter.keys()),
      });
    }
    if (bodyTypeFilter.filter.size > 0) {
      filterIDs.push('bodytype');
      relatedFilters.push({
        id: 'bodytype',
        values: Array.from(bodyTypeFilter.filter.keys()),
      });
    }
    if (fuelFilter.filter.size > 0) {
      filterIDs.push('fuel');
      relatedFilters.push({
        id: 'fuel',
        values: Array.from(fuelFilter.filter.keys()),
      });
    }
    if (relatedFilters.length > 0) {
      trackFilterClick(buttonTextForTracking, temporaryNumberOfCarlines, relatedFilters);
    }
  }, [
    carlineGroupsFilter.filter,
    bodyTypeFilter.filter,
    priceFilter.minPrice,
    priceFilter.maxPrice,
    fuelFilter.filter,
  ]);

  const [resultCountText, setResultCountText] = useState(createResultCountText());
  useEffect(() => {
    // a filter changed
    const isFiltersApplied =
      carlineGroupsFilter.temporaryFilter.size > 0 ||
      bodyTypeFilter.temporaryFilter.size > 0 ||
      priceFilter.temporaryMinPrice > 0 ||
      priceFilter.temporaryMaxPrice > 0 ||
      fuelFilter.temporaryFilter.size > 0;
    if (isFiltersApplied) {
      const newButtonText = () => {
        if (temporaryNumberOfCarlines > 1) {
          return matchI18n('showNumberOfCars', i18nMessages, {
            value: temporaryNumberOfCarlines.toString(),
          });
        }
        if (temporaryNumberOfCarlines === 1) {
          return matchI18n('showNumberOfCar', i18nMessages, {
            value: temporaryNumberOfCarlines.toString(),
          });
        }
        return staticI18nText.noResults;
      };
      setButtonText(newButtonText);
    } else {
      setButtonText(staticI18nText.showAllCars);
    }
  }, [
    carlineGroups,
    carlineGroupsFilter.temporaryFilter,
    bodyTypeFilter.temporaryFilter,
    priceFilter.temporaryMinPrice,
    priceFilter.temporaryMaxPrice,
    fuelFilter.temporaryFilter,
  ]);

  useEffect(() => {
    // a temporary filter changed
    setFilteredCarlineGroups(
      filter(carlineGroups, {
        selectedCarlineGroups: Array.from(carlineGroupsFilter.filter.keys()),
        selectedBodyTypes: Array.from(bodyTypeFilter.filter.keys()),
        selectedFuelTypes: Array.from(fuelFilter.filter.keys()),
        selectedMinPrice: priceFilter.minPrice,
        selectedMaxPrice: priceFilter.maxPrice,
      }),
    );
    setResultCountText(createResultCountText());
  }, [
    carlineGroups,
    carlineGroupsFilter.filter,
    bodyTypeFilter.filter,
    fuelFilter.filter,
    priceFilter.minPrice,
    priceFilter.maxPrice,
  ]);

  const resetFilters = () => {
    carlineGroupsFilter.reset();
    bodyTypeFilter.reset();
    priceFilter.reset();
    fuelFilter.reset();
  };

  const clearFilters = () => {
    carlineGroupsFilter.clearAndSave();
    bodyTypeFilter.clearAndSave();
    priceFilter.clearAndSave();
    fuelFilter.clearAndSave();
  };

  return (
    <FilterContext.Provider
      value={{
        filteredCarlineGroups,
        carlineGroupsFilter,
        bodyTypeFilter,
        priceFilter,
        fuelFilter,
        generalFilterFunctions: {
          resetFilters,
          clearFilters,
          buttonText,
          resultCountText,
        },
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilteredCarlineGroups = () => useContext(FilterContext).filteredCarlineGroups;

export const useCarlineGroupFilter = () => useContext(FilterContext).carlineGroupsFilter;

export const useBodyTypeFilter = () => useContext(FilterContext).bodyTypeFilter;

export const usePriceFilter = () => useContext(FilterContext).priceFilter;

export const useGeneralFilterFunctions = () => useContext(FilterContext).generalFilterFunctions;

export const useFuelTypeFilter = () => useContext(FilterContext).fuelFilter;
