import * as React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  color: ${(props) => props.theme.colors.ui.primary};
  pointer-events: none;
  flex-shrink: 0;
`;

export const EraseIcon: React.FC = () => (
  <StyledIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>erase-small</title>
      <path
        d="M5.5,9 L5.5,19.5 C5.5,20 5.9,20.5 6.5,20.5 L16.5,20.5 C17,20.5 17.5,20 17.5,19.5 L17.5,9 M11.5,5.5 L11.5,3.5 M10,3.5 L13,3.5 M3.5,7.5 L19.5,7.5 L18.1,5.5 L4.9,5.5 C4.9,5.5 3.5,7.5 3.5,7.5 Z"
        stroke="currentColor"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  </StyledIcon>
);
