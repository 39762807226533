import React, { useCallback, useRef } from 'react';
import type { MoxxCarline, MoxxCarlineGroup } from '../../MoxxTypes';
import { useFilteredCarlineGroups } from '../../context/FilterContext';
import { useInitialState } from '../../context/InitialStateContext';
import Card from '../card/Card';
import { NoResults } from '../card/NoResults';
import { Filter } from '../filter/Filter';
import { CarlineGroupWrapper, UngroupedWrapper } from '../wrapper/Wrapper';

const NO_OF_IMG_EAGER_LOADED = 9;

const toCard = (
  handleLazy: () => boolean,
  carlineGroup: MoxxCarlineGroup,
  carline: MoxxCarline,
) => (
  <Card
    key={`${carline.id}`}
    carline={carline}
    carlineGroup={carlineGroup}
    lazyImage={handleLazy()}
  />
);

const ListAndFilter: React.FC = () => {
  const filteredCarlineGroups = useFilteredCarlineGroups();
  const { carlineGroups: unfilteredCarlineGroups } = useInitialState();
  const carlineCounter = useRef(0);
  const handleLazy = useCallback(() => {
    const lazy = carlineCounter.current >= NO_OF_IMG_EAGER_LOADED;
    carlineCounter.current += 1;
    return lazy;
  }, []);

  const totalCarlineCount = (carlineGroups: MoxxCarlineGroup[]): number => {
    return carlineGroups.reduce((totalCarlines, carlineGroup) => {
      return totalCarlines + carlineGroup.carlines.length;
    }, 0);
  };

  const NoResultsContainer = () => {
    const hasNoCarlines = filteredCarlineGroups.length === 0;
    const result = hasNoCarlines ? <NoResults /> : null;
    return result;
  };

  const UnfilteredCarlineGroupsContainer = () => {
    const renderCarlineGroups = () => {
      if (totalCarlineCount(unfilteredCarlineGroups) === totalCarlineCount(filteredCarlineGroups)) {
        return unfilteredCarlineGroups.map((carlineGroup, index) => (
          <CarlineGroupWrapper
            key={carlineGroup.name}
            heading={carlineGroup.name}
            firstItem={index === 0}
            carlineGroupId={carlineGroup.id}
          >
            {carlineGroup.carlines.map((carline) => toCard(handleLazy, carlineGroup, carline))}
          </CarlineGroupWrapper>
        ));
      }
      return null;
    };

    return <>{renderCarlineGroups()}</>;
  };

  const FilteredCarlineGroupsContainer = () => {
    const shouldRenderUngroupedWrapper =
      totalCarlineCount(unfilteredCarlineGroups) !== totalCarlineCount(filteredCarlineGroups);

    return shouldRenderUngroupedWrapper ? (
      <UngroupedWrapper>
        {filteredCarlineGroups.flatMap((carlineGroup) =>
          carlineGroup.carlines.map((carline) => toCard(handleLazy, carlineGroup, carline)),
        )}
      </UngroupedWrapper>
    ) : null;
  };

  return (
    <>
      <Filter />
      <NoResultsContainer />
      <UnfilteredCarlineGroupsContainer />
      <FilteredCarlineGroupsContainer />
    </>
  );
};

export default ListAndFilter;
