import { TagGroup, Text } from '@audi/audi-ui-react';
import React from 'react';
import { EraseIcon } from './icons/EraseIcon';
import { RemoveAllButton, StyledFilterContainer } from './DesktopFilterStyles';
import { createMaxPriceTag, createMinPriceTag, createTags } from './FilterTags';
import { useI18n } from '../../i18n';
import {
  useBodyTypeFilter,
  useCarlineGroupFilter,
  useFuelTypeFilter,
  useGeneralFilterFunctions,
  usePriceFilter,
} from '../../context/FilterContext';
import { useInitialState } from '../../context/InitialStateContext';
import { useCurrencyFormatter } from '../../context/Context';

const FilterState: React.FC = () => {
  const { clearFilters } = useGeneralFilterFunctions();
  const carlineGroupFilter = useCarlineGroupFilter();
  const bodyTypeFilter = useBodyTypeFilter();
  const priceFilter = usePriceFilter();
  const fuelFilter = useFuelTypeFilter();
  const { i18nMessages } = useInitialState();
  const showRemoveAllButton =
    carlineGroupFilter.filter.size +
      bodyTypeFilter.filter.size +
      fuelFilter.filter.size +
      (priceFilter.minPrice ? 1 : 0) +
      (priceFilter.maxPrice ? 1 : 0) >
    1;
  const deleteAllFilters = useI18n('deleteAllFilters');

  return (
    <StyledFilterContainer>
      {/* please don't remove this div otherwise it will apply min style to tag group */}
      <div>
        <TagGroup spaceStackStart="m">
          {createTags('desktop', carlineGroupFilter.filter, carlineGroupFilter.deleteAndSave)}
          {createTags('desktop', bodyTypeFilter.filter, bodyTypeFilter.deleteAndSave)}
          {priceFilter.minPrice > 0 &&
            createMinPriceTag(
              useCurrencyFormatter(priceFilter.minPrice),
              priceFilter.resetMinPrice,
              i18nMessages,
            )}
          {priceFilter.maxPrice > 0 &&
            createMaxPriceTag(
              useCurrencyFormatter(priceFilter.maxPrice),
              priceFilter.resetMaxPrice,
              i18nMessages,
            )}
          {createTags('desktop', fuelFilter.filter, fuelFilter.deleteAndSave)}
          {showRemoveAllButton && (
            <RemoveAllButton
              data-testid="remove-all-filters"
              variant="text"
              spaceStackStart="m"
              spaceInlineStart="l"
              icon={<EraseIcon />}
              onClick={() => {
                clearFilters();
              }}
            >
              <Text variant="copy1">{deleteAllFilters}</Text>
            </RemoveAllButton>
          )}
        </TagGroup>
      </div>
    </StyledFilterContainer>
  );
};

export default FilterState;
