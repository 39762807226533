import * as React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  color: ${(props) => props.theme.colors.ui.primary};
  pointer-events: none;
  flex-shrink: 0;
`;

export const CloseIcon: React.FC = () => (
  <StyledIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>cancel-small</title>
      <path
        d="M5.9,17.1 L17.1,5.9 M5.9,5.9 L17.1,17.1"
        stroke="currentColor"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  </StyledIcon>
);
