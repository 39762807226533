import React from 'react';
import { TrackingDataV2 } from '@oneaudi/tracking-service';
import { useLogger, useTrackingService } from './Context';
import { checkLinkTarget, completeTargetURL } from '../utils/trackingUtils';

export interface CtaTexts {
  primary: string;
  secondary: string;
}

export interface AppliedFilter {
  id: string;
  values: string[] | number[];
}

export interface TrackingManager {
  ready(version: string): void;
  click(
    targetURL: string,
    label: string,
    linkValue: string,
    carlineGroup: string,
    carline: string,
    amount?: number,
  ): void;
  navigation(label: string, carlineGroup: string, carline: string, carsInStock: string): void;
  trackFilterClick(label: string, numberOfCars: number, relatedFilters: AppliedFilter[]): void;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
  componentUpdate: {
    componentInfo: {
      implementer: number;
      version: string;
    };
  };
}

function createTrackingPayload(
  action: string,
  name: string,
  attributes: Record<string, unknown>,
): TrackingDataV2 {
  const defaultAttributes = {
    componentName: 'model-overview',
  };

  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
}

function createComponentPayload(version: string) {
  return {
    componentInfo: {
      implementer: 2,
      version,
    },
  };
}

export function useTrackingManager(): TrackingManager {
  const logger = useLogger();
  const trackingService = useTrackingService();

  return React.useMemo((): TrackingManager => {
    function track(...args: Parameters<typeof createTrackingPayload>): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        if (logger) logger.debug('Sending tracking event: ', payload);
        trackingService.track(payload);
      }
    }

    function trackWithComponent(
      version: string,
      ...args: Parameters<typeof createTrackingPayload>
    ): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);

        const componentPayload = createComponentPayload(version);

        logger?.debug('Sending tracking event: ', {
          ...payload,
          datalayerAdditions: componentPayload,
        });

        const x: TrackingDataV2 = {
          ...payload,
          componentUpdate: componentPayload,
        };
        trackingService.track(x);
      }
    }

    return {
      ready(version) {
        trackWithComponent(version, 'feature_app_ready', 'model-overview - feature app ready', {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        });
      },
      click(targetURL, label, linkValue, carlineGroup, carline, amount) {
        track(checkLinkTarget(targetURL), `model overview - click on '${linkValue}'`, {
          label,
          targetURL: completeTargetURL(targetURL),
          elementName: 'text link',
          value: `${carlineGroup}~${carline}`,
          pos: amount ? amount?.toString() : '',
        });
      },
      navigation(label, carlineGroup, carline, carsInStock) {
        track('navigation', 'model overview - click on vtp menu', {
          label,
          targetURL: '',
          clickID: '',
          elementName: 'text link',
          value: `${carlineGroup}~${carline}`,
          pos: carsInStock,
        });
      },
      trackFilterClick(label, numberOfCars, relatedFilters) {
        track('filter', 'model overview - apply filter', {
          label,
          targetURL: '',
          clickID: '',
          elementName: 'button',
          value: '',
          pos: numberOfCars.toString(),
          relatedFilters,
        });
      },
    };
  }, [trackingService]);
}
