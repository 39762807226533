import { LayoutItem, Tag, TagGroup, Text } from '@audi/audi-ui-react';
import { FootnoteReferenceV2 } from '@oneaudi/feature-app-utils';
import React from 'react';
import type { MoxxCarline, MoxxCarlineGroup } from '../../MoxxTypes';
import {
  useCurrencyFormatter,
  useLogger,
  usePriceFootnotes,
  useShowBasePrice,
  useShowCaeData,
  useShowConfigureCTA,
  useShowNewAndUsedCarsCTA,
} from '../../context/Context';
import { useInitialState } from '../../context/InitialStateContext';
import { matchI18n, useI18n } from '../../i18n';
import { appendWidthToUrl } from '../../utils/imgUtils';
import { getStockAmount } from '../../utils/utils';
import CardButtons from './CardButtons';
import {
  StyledCard,
  StyledCardHeader,
  StyledCardHeadingContainer,
  StyledCardImg,
  StyledGrid,
  StyledText,
} from './CardStyles';
import ConsumptionAndEmission from './ConsumptionAndEmission';

const MAX_IM_WIDTH = 308;

export interface CardProps {
  readonly carline: MoxxCarline;
  readonly carlineGroup: MoxxCarlineGroup;
  readonly lazyImage: boolean;
}

const Card: React.FC<CardProps> = ({ carline, carlineGroup, lazyImage }) => {
  const logger = useLogger();
  const { i18nMessages } = useInitialState();
  const showBasePrice = useShowBasePrice();
  const showCaeData = useShowCaeData();
  const ctaDisplayOptions = {
    showConfigureCTA: useShowConfigureCTA(),
    showNewAndUsedCarsCTA: useShowNewAndUsedCarsCTA(),
  };
  const electricTag = useI18n('electricTag');
  const hybridTag = useI18n('hybridTag');
  const priceFootnotes = usePriceFootnotes();
  const carsInStock = getStockAmount(carline);

  return (
    <StyledCard data-testid="card" data-carlineid={carline.id} data-configurator={!carline.isFake}>
      <StyledCardHeader>
        <LayoutItem>
          <StyledCardHeadingContainer>
            <LayoutItem>
              <Text variant="order4" data-testid="carlineName">
                {carline.name}
              </Text>
            </LayoutItem>
          </StyledCardHeadingContainer>
          {carline.vehicleType === 'PHEV' || carline.vehicleType === 'BEV'}
          <TagGroup spaceStackStart="xs">
            {carline.vehicleType === 'PHEV' && (
              <Tag data-testid="hybridTag" label={hybridTag} size="small" role="listitem" />
            )}
            {carline.vehicleType === 'BEV' && (
              <Tag data-testid="electroTag" label={electricTag} size="small" role="listitem" />
            )}
          </TagGroup>
        </LayoutItem>
      </StyledCardHeader>
      {carline.image && (
        <StyledCardImg
          src={appendWidthToUrl(carline.image, MAX_IM_WIDTH, logger)}
          srcSet={`${appendWidthToUrl(carline.image, MAX_IM_WIDTH, logger)} 1x,
            ${appendWidthToUrl(carline.image, MAX_IM_WIDTH * 2, logger)} 2x`}
          data-testid="image"
          alt={carline.name}
          width={320}
          height={138}
          loading={lazyImage ? 'lazy' : undefined}
        />
      )}
      {showBasePrice && carline.minPrice?.value && !carline.isFake && (
        <StyledGrid>
          <Text variant="copy1" data-testid="price" spaceStackEnd="xxs">
            {matchI18n('basePriceText', i18nMessages, {
              value: useCurrencyFormatter(carline.minPrice.value),
            })}
            {priceFootnotes?.map((footnote: string, i: number) => (
              <React.Fragment key={footnote}>
                {i > 0 && <sup>{', '}</sup>}
                <FootnoteReferenceV2 footnoteId={footnote} data-testid={`price-footnote_${i}`} />
              </React.Fragment>
            ))}
          </Text>
          <StyledText
            variant="copy2"
            spaceStackStart={priceFootnotes?.length !== 0 ? 'xxs' : undefined}
          >
            {matchI18n('vat', i18nMessages)}
          </StyledText>
        </StyledGrid>
      )}

      <CardButtons
        carline={carline}
        carlineGroup={carlineGroup}
        isPriceDisplayed={
          showBasePrice && !!(carline.minPrice?.value && carline.minPrice.value > 0)
        }
        ctaDisplayOptions={ctaDisplayOptions}
      />
      {showCaeData && carline.consumptionAndEmission && (
        <LayoutItem
          spaceStackStart={
            !carline.configureLinkUrl && !carline.discoverLinkUrl && carsInStock === 0
              ? undefined
              : 's'
          }
          data-testid="consumptionAndEmission"
        >
          <ConsumptionAndEmission
            consumptionData={carline.consumptionAndEmission}
            carlineName={carline.name}
          />
        </LayoutItem>
      )}
    </StyledCard>
  );
};

export default Card;
