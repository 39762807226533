import {
  Accordion,
  AccordionSection,
  Button,
  ButtonGroup,
  FormGroup,
  Select,
  Text,
} from '@audi/audi-ui-react';
import React from 'react';
import { useModalOpenState, useShowBasePrice } from '../../context/Context';
import {
  useBodyTypeFilter,
  useCarlineGroupFilter,
  useFuelTypeFilter,
  useGeneralFilterFunctions,
  usePriceFilter,
} from '../../context/FilterContext';
import { useInitialState } from '../../context/InitialStateContext';
import { matchI18n } from '../../i18n';
import { splitArrayInTwo } from '../../utils/splitArray';
import { mapToFilterCheckboxes } from './FilterCheckbox';
import { StyledPriceGrid } from './PriceFilter';
import { CloseIcon } from './icons/CloseIcon';
import { EraseIcon } from './icons/EraseIcon';
import { FilterIcon } from './icons/FilterIcon';
import {
  AccordionContainer,
  AccordionHeading,
  ButtonContainer,
  CloseButton,
  MobileContainer,
  MobileFilterContainer,
  StyledBodyTypeGrid,
  StyledGrid,
} from './mobileFilterStyles';

const MobileFilter = () => {
  const {
    carlineGroups: unfilteredCarlineGroups,
    bodyTypes: unfilteredBodyTypes,
    i18nMessages,
    // fuelTypes,
  } = useInitialState();
  const [isModalOpen, { openModal, closeModal }] = useModalOpenState();
  const { resetFilters, clearFilters, buttonText } = useGeneralFilterFunctions();
  const carlineGroupFilter = useCarlineGroupFilter();
  const bodyTypeFilter = useBodyTypeFilter();
  const fuelFilter = useFuelTypeFilter();
  const priceFilter = usePriceFilter();
  const priceFilterSize = (priceFilter.minPrice ? 1 : 0) + (priceFilter.maxPrice ? 1 : 0);
  const showBasePrice = useShowBasePrice();
  const [bodyTypesGroup1, bodyTypesGroup2] = splitArrayInTwo(unfilteredBodyTypes);
  // const [fuelTypesGroup1, fuelTypesGroup2] = splitArrayInTwo(fuelTypes);
  const [carlineGroupsGroup1, carlineGroupsGroup2] = splitArrayInTwo(
    unfilteredCarlineGroups.map(({ id, name }) => [id, name]),
  );
  const amountOfTemporaryCarlineFiltersSet = carlineGroupFilter.temporaryFilter.size;
  const amountOfTemporaryBodyFiltersSet = bodyTypeFilter.temporaryFilter.size;
  // const amountOfTemporaryFuelFiltersSet = fuelFilter.temporaryFilter.size;
  const amountOfTemporaryPriceFiltersSet =
    (priceFilter.temporaryMinPrice ? 1 : 0) + (priceFilter.temporaryMaxPrice ? 1 : 0);

  const amountOfAllFiltersSet =
    carlineGroupFilter.filter.size +
    bodyTypeFilter.filter.size +
    priceFilterSize +
    fuelFilter.filter.size;

  const isFilterSet = amountOfAllFiltersSet > 0;

  const modelSectionLabel = amountOfTemporaryCarlineFiltersSet
    ? matchI18n('modelFilterTextWithValue', i18nMessages, {
        value: amountOfTemporaryCarlineFiltersSet.toString(),
      })
    : i18nMessages.modelFilterText;

  const bodyTypeSectionLabel = amountOfTemporaryBodyFiltersSet
    ? matchI18n('bodyFilterTextWithValue', i18nMessages, {
        value: amountOfTemporaryBodyFiltersSet.toString(),
      })
    : i18nMessages.bodyFilterText;

  const priceTypeSectionLabel = amountOfTemporaryPriceFiltersSet
    ? matchI18n('priceFilterTextWithValue', i18nMessages, {
        value: amountOfTemporaryPriceFiltersSet.toString(),
      })
    : i18nMessages.priceFilterText;

  // const fuelTypeSectionLabel = amountOfTemporaryFuelFiltersSet
  //   ? matchI18n('fuelFilterTextWithValue', i18nMessages, {
  //       value: amountOfTemporaryFuelFiltersSet.toString(),
  //     })
  //   : i18nMessages.fuelFilterText;

  return (
    <MobileFilterContainer className={isModalOpen ? 'open' : ''}>
      <MobileContainer>
        <ButtonGroup variant="text-buttons">
          <Button
            variant="text"
            icon={<FilterIcon />}
            onClick={() => {
              resetFilters();
              openModal();
            }}
          >
            <Text weight={isFilterSet ? 'bold' : 'normal'}>
              {isFilterSet
                ? matchI18n('filtersTextWithValue', i18nMessages, {
                    value: amountOfAllFiltersSet.toString(),
                  })
                : matchI18n('filtersText', i18nMessages)}
            </Text>
          </Button>
          {isFilterSet && (
            <Button
              variant="text"
              icon={<EraseIcon />}
              onClick={() => {
                clearFilters();
              }}
            >
              <Text variant="copy1"> {matchI18n('deleteAllFilters', i18nMessages)}</Text>
            </Button>
          )}
        </ButtonGroup>
      </MobileContainer>

      {isModalOpen && (
        <AccordionContainer>
          <Accordion>
            <AccordionHeading>
              <Text variant="order4">{matchI18n('filtersText', i18nMessages)}</Text>
              <CloseButton type="button" onClick={() => closeModal()}>
                <CloseIcon />
              </CloseButton>
              {/* {isFilterSet && (
                <TagGroup spaceStackStart="m">
                  {createTags(
                    'mobile',
                    carlineGroupFilter.filter,
                    carlineGroupFilter.deleteAndSave
                  )}
                  {createTags('mobile', bodyTypeFilter.filter, bodyTypeFilter.deleteAndSave)}
                  {priceFilter.minPrice > 0 &&
                    createMinPriceTag(
                      priceFilter.minPrice,
                      priceFilter.resetMinPrice,
                      i18nMessages
                    )}
                  {priceFilter.maxPrice > 0 &&
                    createMaxPriceTag(
                      priceFilter.maxPrice,
                      priceFilter.resetMaxPrice,
                      i18nMessages
                    )}
                  {createTags('mobile', fuelFilter.filter, fuelFilter.deleteAndSave)}
                </TagGroup>
              )} */}
            </AccordionHeading>
            <AccordionSection id="section-1__basic" label={modelSectionLabel} headingLevel="h3">
              <FormGroup>
                <StyledGrid>
                  <div>{mapToFilterCheckboxes(carlineGroupsGroup1, carlineGroupFilter)}</div>
                  <div>{mapToFilterCheckboxes(carlineGroupsGroup2, carlineGroupFilter)}</div>
                </StyledGrid>
              </FormGroup>
            </AccordionSection>
            <AccordionSection id="section-2__basic" label={bodyTypeSectionLabel} headingLevel="h3">
              <FormGroup>
                <StyledBodyTypeGrid>
                  <StyledGrid>
                    <div>{mapToFilterCheckboxes(bodyTypesGroup1, bodyTypeFilter)}</div>
                    <div>{mapToFilterCheckboxes(bodyTypesGroup2, bodyTypeFilter)}</div>
                  </StyledGrid>
                </StyledBodyTypeGrid>
              </FormGroup>
            </AccordionSection>
            {showBasePrice ? (
              <AccordionSection
                id="section-3__basic"
                label={priceTypeSectionLabel}
                headingLevel="h3"
              >
                <FormGroup>
                  {/* implementation will be added in future */}
                  {/* <StyledPriceGrid>
                  <RadioButton inputId="radio-button-basic">{i18nMessages.totalPrice}</RadioButton>
                  <RadioButton inputId="radio-button-basic">{i18nMessages.leasingrate}</RadioButton>
                </StyledPriceGrid> */}
                  <StyledPriceGrid>
                    <Select
                      inputId="select__idle"
                      label={`${matchI18n('from', i18nMessages)} ${matchI18n(
                        'currency',
                        i18nMessages,
                      )}`}
                      onChange={(e) => {
                        const price = parseInt(e.currentTarget.value, 10);
                        priceFilter.setMinPrice(price);
                      }}
                      value={priceFilter.temporaryMinPrice.toString()}
                      required
                    >
                      <option value="0">{matchI18n('anyPrice', i18nMessages)}</option>
                      {priceFilter.createMinPriceOptions()}
                    </Select>
                    <Select
                      inputId="select__idle"
                      label={`${matchI18n('to', i18nMessages)}  ${matchI18n(
                        'currency',
                        i18nMessages,
                      )}`}
                      onChange={(e) => {
                        priceFilter.setMaxPrice(parseInt(e.currentTarget.value, 10));
                      }}
                      value={priceFilter.temporaryMaxPrice.toString()}
                      required
                    >
                      <option value="0">{matchI18n('anyPrice', i18nMessages)}</option>
                      {priceFilter.createMaxPriceOptions()}
                    </Select>
                  </StyledPriceGrid>
                </FormGroup>
              </AccordionSection>
            ) : (
              <></>
            )}
            {/* <AccordionSection id="section-4__basic" label={fuelTypeSectionLabel} headingLevel="h3">
              <FormGroup>
                <StyledGrid>
                  <div>{mapToFilterCheckboxes(fuelTypesGroup1, fuelFilter)}</div>
                  <div>{mapToFilterCheckboxes(fuelTypesGroup2, fuelFilter)}</div>
                </StyledGrid>
              </FormGroup>
            </AccordionSection> */}
          </Accordion>
          <ButtonContainer>
            <Button
              variant="secondary"
              stretch
              onClick={() => {
                carlineGroupFilter.save();
                bodyTypeFilter.save();
                priceFilter.save();
                fuelFilter.save();
                closeModal();
              }}
            >
              {buttonText}
            </Button>
          </ButtonContainer>
        </AccordionContainer>
      )}
    </MobileFilterContainer>
  );
};

export default MobileFilter;
