import styled from 'styled-components';
import { Theme } from '@audi/audi-ui-react';
import {
  PAGE_MARGIN_XS,
  PAGE_MARGIN_S,
  PAGE_MARGIN_M,
  PAGE_MARGIN_L,
} from '@audi/audi-ui-design-tokens';

export const LayoutItemWithPageMargin = styled.div`
  margin-top: var(${(props) => props.theme.responsive.spacing.xxxl});
  margin-bottom: var(${(props) => props.theme.responsive.spacing.xxxl});
  // modal Open styles to block scrolling when modal is open and stay at top of the page when modal closes
  &.open {
    min-height: 100vh;
    position: fixed;
    overflow-y: scroll;
    width: 100%;
    margin: 0;
    z-index: 2000;
  }
  ${({ theme }: { theme: Theme }) => `
@media (min-width: ${theme.breakpoints.xs}px) {
      margin-inline-start: ${PAGE_MARGIN_XS}px;
      margin-inline-end: ${PAGE_MARGIN_XS}px;
    }
    @media (min-width: ${theme.breakpoints.s}px) {
      margin-inline-start: ${PAGE_MARGIN_S}px;
      margin-inline-end: ${PAGE_MARGIN_S}px;
    }
    @media (min-width: ${theme.breakpoints.m}px) {
      margin-inline-start: ${PAGE_MARGIN_M}px;
      margin-inline-end: ${PAGE_MARGIN_M}px;
    }
    @media (min-width: ${theme.breakpoints.l}px) {
        margin-inline-start: ${PAGE_MARGIN_L}px;
        margin-inline-end: ${PAGE_MARGIN_L}px;
    }
    /* temporary workaround to adjust style to NeMo Model Finder template in AEM */
    @media (min-width: 1600px)  {
      margin-inline-start: 0;
      margin-inline-end: 0;
    }
  `}
`;
