import { Button, useSpacing } from '@audi/audi-ui-react';
import React from 'react';
import { useTrackingManager } from '../../context/useTrackingManager';
import { CardButtonsProps, I18nTexts } from '../../FeatureAppTypes';
import { useI18n } from '../../i18n';
import NewAndUsedCarsPopover, { StockCar } from './NewAndUsedCarsPopover';
import { StyledButtonWrapper } from './CardStyles';

const CardButtons = React.memo(
  ({ carlineGroup, carline, isPriceDisplayed, ctaDisplayOptions }: CardButtonsProps) => {
    const availableCarsInStock = carline.availableStock;

    let carsInStock = 0;
    if (availableCarsInStock?.newCars && availableCarsInStock?.newCars?.amount) {
      carsInStock += availableCarsInStock.newCars.amount;
    }
    if (availableCarsInStock?.usedCars && availableCarsInStock.usedCars?.amount) {
      carsInStock += availableCarsInStock.usedCars.amount;
    }

    const newCars = availableCarsInStock?.newCars as StockCar;
    const usedCars = availableCarsInStock?.usedCars as StockCar;

    const { discoverLinkUrl, configureLinkUrl } = carline;

    if (!discoverLinkUrl && !configureLinkUrl && carsInStock === 0) {
      return null;
    }
    const { click } = useTrackingManager();
    const i18nButtonTexts: I18nTexts = {
      discoverCTALabel: useI18n('discoverCTALabel'),
      configureCTALabel: useI18n('configureCTALabel'),
    };
    const useTopPadding =
      !isPriceDisplayed && (discoverLinkUrl || configureLinkUrl || carsInStock > 0);
    const styledSpacing = useSpacing({
      spaceStackStart: useTopPadding ? 'xxs' : 'xs',
    });

    return (
      <StyledButtonWrapper direction="column" outerSpacing={styledSpacing}>
        {discoverLinkUrl && (
          <Button
            data-testid="discoverButton"
            variant="text"
            size="small"
            spaceStackEnd={!configureLinkUrl && carsInStock === 0 ? undefined : 's'}
            href={discoverLinkUrl}
            onClick={() =>
              click(
                discoverLinkUrl,
                i18nButtonTexts.discoverCTALabel,
                'discover',
                carlineGroup.name,
                carline.name,
              )
            }
          >
            {i18nButtonTexts.discoverCTALabel}
          </Button>
        )}
        {ctaDisplayOptions.showConfigureCTA && configureLinkUrl && (
          <Button
            data-testid="configureButton"
            variant="text"
            size="small"
            href={configureLinkUrl}
            spaceStackEnd={carsInStock === 0 ? undefined : 's'}
            onClick={() =>
              click(
                configureLinkUrl,
                i18nButtonTexts.configureCTALabel,
                'configure',
                carlineGroup.name,
                carline.name,
              )
            }
          >
            {i18nButtonTexts.configureCTALabel}
          </Button>
        )}
        {ctaDisplayOptions.showNewAndUsedCarsCTA && carsInStock > 0 && (
          <NewAndUsedCarsPopover
            newCars={newCars}
            usedCars={usedCars}
            carsInStock={carsInStock}
            carlineGroup={carlineGroup.name}
            carline={carline.name}
          />
        )}
      </StyledButtonWrapper>
    );
  },
);

export default CardButtons;
