import { LayoutItem, Text } from '@audi/audi-ui-react';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: grid;
  gap: var(${(props) => props.theme.responsive.spacing.l});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

interface WrapperProps extends PropsWithChildren {
  readonly firstItem: boolean;
}

const Wrapper: React.FC<WrapperProps> = ({ children, firstItem }) => (
  <LayoutItem spaceStackStart={firstItem ? 'xxl' : 'xxxl'}>{children}</LayoutItem>
);

interface CarlineGroupWrapperProps extends WrapperProps {
  readonly heading: string;
  readonly carlineGroupId: string;
}

const CarlineGroupWrapper: React.FC<CarlineGroupWrapperProps> = ({
  children,
  heading,
  firstItem,
  carlineGroupId,
}) => {
  return (
    <Wrapper firstItem={firstItem}>
      <Text variant="order2" data-testid="carlineGroupName" spaceStackEnd="xl">
        {heading}
      </Text>
      <StyledWrapper data-testid="carlineGroup" data-carlinegroupid={carlineGroupId}>
        {children}
      </StyledWrapper>
    </Wrapper>
  );
};

const UngroupedWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Wrapper firstItem>
      <StyledWrapper data-testid="carlineGroup">{children}</StyledWrapper>
    </Wrapper>
  );
};

export { CarlineGroupWrapper, CarlineGroupWrapperProps, UngroupedWrapper };
