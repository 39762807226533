import { MoxxCarlineGroup } from '../MoxxTypes';

interface FilterOptions {
  readonly selectedCarlineGroups?: string[];
  readonly selectedBodyTypes?: string[];
  readonly selectedMinPrice?: number;
  readonly selectedMaxPrice?: number;
  readonly selectedFuelTypes?: string[];
}

const filter = (
  carlineGroups: MoxxCarlineGroup[],
  {
    selectedCarlineGroups = [],
    selectedBodyTypes = [],
    // selectedFuelTypes = [],
    selectedMinPrice = 0,
    selectedMaxPrice = 0,
  }: FilterOptions,
): MoxxCarlineGroup[] => {
  let result = carlineGroups;
  if (selectedCarlineGroups.length > 0) {
    result = carlineGroups.filter(({ id }) => selectedCarlineGroups.indexOf(id) >= 0);
  }
  if (selectedBodyTypes.length > 0) {
    result = result
      .flatMap(({ carlines }, i) => {
        const filteredCarlines = carlines.filter(({ categories }) =>
          categories.some((category) => selectedBodyTypes.includes(category)),
        );
        return {
          ...carlineGroups[i],
          carlines: filteredCarlines,
        };
      })
      .filter(({ carlines }) => carlines.length !== 0);
  }

  if (selectedMinPrice > 0) {
    result = result
      .flatMap(({ carlines }, i) => {
        const filteredCarlines = carlines.filter(
          ({ minPrice }) => minPrice?.value && minPrice.value >= selectedMinPrice,
        );
        return {
          ...carlineGroups[i],
          carlines: filteredCarlines,
        };
      })
      .filter(({ carlines }) => carlines.length !== 0);
  }
  if (selectedMaxPrice > 0) {
    result = result
      .flatMap(({ carlines }, i) => {
        const filteredCarlines = carlines.filter(
          ({ minPrice }) => minPrice?.value && minPrice.value <= selectedMaxPrice,
        );
        return {
          ...carlineGroups[i],
          carlines: filteredCarlines,
        };
      })
      .filter(({ carlines }) => carlines.length !== 0);
  }

  // TODO this filter needs rework to the vehicleType field when re-activating this filter
  // if (selectedFuelTypes.length > 0) {
  // result = result
  //   .flatMap(({ carlines }, i) => {
  //     const filteredCarlines = carlines.filter(({ fuelTypes }) =>
  //       selectedFuelTypes.some((fuelType) => fuelTypes.toString().includes(fuelType))
  //     );
  //     if (selectedFuelTypes.includes('HYBRID')) {
  //       // codeb has to be added //
  //     }
  //     return {
  //       ...carlineGroups[i],
  //       carlines: filteredCarlines,
  //     };
  //   })
  //   .filter(({ carlines }) => carlines.length !== 0);
  // }

  return result;
};

const getNumberOfCarlinesAfterFilter = (
  carlineGroups: MoxxCarlineGroup[],
  filterOptions: FilterOptions,
) => {
  try {
    return filter(carlineGroups, filterOptions)
      .map(({ carlines }) => carlines.length)
      .reduce((a, b) => a + b);
  } catch (e) {
    return 0;
  }
};

export { FilterOptions, filter, getNumberOfCarlinesAfterFilter };
