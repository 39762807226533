import { Content, HeadlessContent } from '../EditorContentTypes';

export const mapHeadlessContent = (content: Content | HeadlessContent): Content => {
  if ('__type' in content && content.__type === 'aem-headless') {
    const normalizedContent: Content = {
      priceDisplayOption: {
        showBasePrice: content.fields.priceDisplayOption_showBasePrice,
      },
      errorCorrection: {
        currencyCodeOverride: content.fields.errorCorrection_currencyCodeOverride ?? '',
      },
      caeDisplayOption: {
        showCae: content.fields.caeDisplayOption_showCae,
      },
      ctaDisplayOptions: {
        showConfigureCTA: content.fields.ctaDisplayOptions_showConfigureCTA,
        showNewAndUsedCarsCTA: content.fields.ctaDisplayOptions_showNewAndUsedCarsCTA,
      },
      priceFootnotes: [],
      priceFilterOptions: {
        minPriceOptions: [],
        maxPriceOptions: [],
      },
      disclaimer: {
        text: content.fields.disclaimer_text ?? '',
      },
      preFilteredCarlineGroup: {
        id: content.fields.preFilteredCarlineGroup_id ?? '',
      },
    };
    if (content.fields.priceFootnotes && content.fields.priceFootnotes.length > 0) {
      content.fields.priceFootnotes.forEach((footnote) => {
        normalizedContent.priceFootnotes?.push(footnote);
      });
    }
    if (
      content.fields.priceFilterOptions_minPriceOptions &&
      content.fields.priceFilterOptions_minPriceOptions.length > 0
    ) {
      content.fields.priceFilterOptions_minPriceOptions.forEach((minPriceOption) => {
        normalizedContent.priceFilterOptions?.minPriceOptions.push(minPriceOption);
      });
    }
    if (
      content.fields.priceFilterOptions_maxPriceOptions &&
      content.fields.priceFilterOptions_maxPriceOptions.length > 0
    ) {
      content.fields.priceFilterOptions_maxPriceOptions.forEach((maxPriceOption) => {
        normalizedContent.priceFilterOptions?.maxPriceOptions.push(maxPriceOption);
      });
    }
    return normalizedContent;
  }
  return content as Content;
};
