import React, { useEffect, useState } from 'react';
import { useBodyTypeFilter } from '../../context/FilterContext';
import { useInitialState } from '../../context/InitialStateContext';
import { splitArrayInThree, splitArrayInTwo } from '../../utils/splitArray';
import DesktopFilter from './DesktopFilter';

const isMax1024Px = () => window.matchMedia('(max-width: 1024px)').matches;

type BodyTypeArray = [string, string][];

const splitBodyTypeGroups = (
  bodyTypes: BodyTypeArray,
  isTablet: boolean,
): [BodyTypeArray, BodyTypeArray, BodyTypeArray?] => {
  if (bodyTypes.length > 12 && !isTablet) {
    return splitArrayInThree(bodyTypes);
  }
  return [...splitArrayInTwo(bodyTypes), undefined];
};

const DesktopBodyFilter: React.FC = () => {
  const { bodyTypes } = useInitialState();
  const bodyTypeFilter = useBodyTypeFilter();
  const [[group1, group2, group3], setBodyTypeGroups] = useState<
    [BodyTypeArray, BodyTypeArray, BodyTypeArray?]
  >([[], []]);

  useEffect(() => {
    let mounted = true;
    let isTablet = isMax1024Px();
    setBodyTypeGroups(splitBodyTypeGroups(bodyTypes, isTablet));
    const handleResize = () => {
      if (mounted) {
        const newResolution = isMax1024Px();
        if (isTablet !== newResolution) {
          isTablet = newResolution;
          setBodyTypeGroups(splitBodyTypeGroups(bodyTypes, newResolution));
        }
      }
    };
    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      mounted = false;
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <DesktopFilter
      testId="bodytype-filter"
      filter={bodyTypeFilter}
      filterEntries1={group1}
      filterEntries2={group2}
      filterEntries3={group3}
      threeCols={bodyTypes.length > 12}
      filterText="bodyFilterText"
      filterTextWithValue="bodyFilterTextWithValue"
    />
  );
};

export default DesktopBodyFilter;
