import { Checkbox } from '@audi/audi-ui-react';
import * as React from 'react';
import styled from 'styled-components';
import { Filter } from '../../context/FilterContext';

const StyledCheckbox = styled(Checkbox)`
  input {
    /* the basic.forms.legacy.css in AEM overwrites this property and causes a streched checkbox.
     Can be removed if the legacy.css is removed from AEM */
    height: 20px !important;
  }
`;

export const mapToFilterCheckboxes = (
  filterEntries: string[][],
  filter: Filter,
  testId?: string,
) => {
  return filterEntries.map(([id, name], index, array) => (
    <StyledCheckbox
      data-testid={testId}
      key={id}
      inputId={id}
      required
      spaceStackEnd={index + 1 === array.length ? undefined : 'l'}
      checked={filter.has(id)}
      onChange={(e) => {
        if (e.target.checked) {
          filter.set(id, name);
        } else {
          filter.delete(id);
        }
      }}
      value={id}
    >
      {name}
    </StyledCheckbox>
  ));
};
